import React, {useEffect, useRef, useState} from 'react'
import * as THREE from 'three'
import {CameraHelper} from 'three'
import gsap, {Power0, Power1} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import {ScrollSmoother} from 'gsap/ScrollSmoother'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin'
import {OrbitControls, OrthographicCamera, PerspectiveCamera, Trail, useHelper} from '@react-three/drei'
import {button, useControls} from 'leva'
import {useFrame} from '@react-three/fiber'

gsap.registerPlugin(ScrollTrigger, ScrollSmoother, ScrollToPlugin)

let smoother = ScrollSmoother.create({
	wrapper: '#smooth-wrapper',
	content: '#smooth-content',
	smooth: 1,

	ignoreMobileResize: true,
})
const Z_Camera = ({setHasScrolled}) => {
	//region References
	const perspectiveCameraRef = useRef<THREE.PerspectiveCamera>()
	//endregion

	//region Animations
	let iteration = 0
	let animationCanceled = false

	const setAnimationCancelled = () => {
		animationCanceled = true
		setTimeout(() => {
			animationCanceled = false
		}, 200)
	}

	const wrapForward = () => {
		if (!animationCanceled) {
			++iteration
			setAnimationCancelled()
			// gsap.set(window, {scrollTo: lookingAtLogoPosition})
			smoother.scrollTo( lookingAtLogoPosition, false )
		}
	}

	const wrapBackward = () => {
		if (!animationCanceled) {
			if (iteration > 0) {
				--iteration
				setAnimationCancelled()
				// gsap.set(window, {scrollTo: lookingAtLogoEndPosition})
				smoother.scrollTo(lookingAtLogoEndPosition, false)
			}
		}
	}

	const movingFromStartToEndOfWallDuration = 1
	const angleDuration = .6
	let scrollTimeline = null

	let lookingAtLogoPosition, lookingAtLogoEndPosition;

	useEffect(() => {
		//region Scroll trigger
		scrollTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: '#threejs_container',
				start: 'top top',
				end: 'max',
				scrub: true,

				invalidateOnRefresh: true,
				immediateRender: false,


				onEnter: self => {
					setHasScrolled(true)
				},
				onLeaveBack: self => {
					setHasScrolled(false)
				},
			},
			defaults: {
				ease: Power0.easeNone,
				duration: 1,
			},
		})

		//region Move forward to GAX Logo
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			z: 10,
		})
		.call(() => {
			if (scrollTimeline.scrollTrigger.direction < 0) {
				wrapBackward()
			}
		})
		.addLabel('lookingAtLogo', '>.001')
		//endregion
		//region Read introduction
		// Change position
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: .5,
			x: -.7,
			z: 4.2,
		})
		// Change rotation
		scrollTimeline.to(perspectiveCameraRef.current.rotation, {
			duration: 1,
			y: Math.PI - Math.PI / 5,
			ease: Power1.easeOut,
		}, '<')
		// Move forward
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: .5,
			x: -2,
			z: 6,
		}, '<.5')
		//endregion
		//region First wall
		scrollTimeline.to(perspectiveCameraRef.current.rotation, {
			y: Math.PI / 2 + Math.PI / 8,
			ease: Power1.easeIn,
		})
		// Move forward and turn to see forward
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			x: -2,
			z: 4,
		}, '<')
		// Move from start to end
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration,
			z: -4,
		})
		.to(perspectiveCameraRef.current.rotation, {
			duration: movingFromStartToEndOfWallDuration,
			y: Math.PI / 2 - Math.PI / 8,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			x: -.9,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			x: -2,
			ease: Power1.easeIn,
		}, '>')
		//endregion
		//region Second wall
		// Turn and move to see the wall
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			z: -2,
			ease: Power1.easeIn,
		})
		.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			x: -4,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.rotation, {
			duration: angleDuration,
			y: Math.PI / 8,
		}, '<')
		// Move from start to end and turn to see forward
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration,
			x: 4,
		})
		.to(perspectiveCameraRef.current.rotation, {
			duration: movingFromStartToEndOfWallDuration,
			y: -Math.PI / 8,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			z: -.9,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			z: -2,
			ease: Power1.easeIn,
		}, '>')
		//endregion
		//region Third wall
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			x: 2,
			ease: Power1.easeIn,
		})
		.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			z: -4,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.rotation, {
			duration: angleDuration,
			y: -Math.PI / 2 + Math.PI / 8,
		}, '<')
		// Turn and move to see the wall
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration,
			z: 4,
		})
		.addLabel('endOfThirdWall', '>')
		.to(perspectiveCameraRef.current.rotation, {
			duration: movingFromStartToEndOfWallDuration,
			y: -Math.PI / 2 - Math.PI / 8,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			x: .9,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.position, {
			duration: movingFromStartToEndOfWallDuration / 2,
			x: 2,
			ease: Power1.easeIn,
		}, '>')
		//endregion
		//region Contact
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			x: 1,
			ease: Power1.easeIn,
		})
		.to(perspectiveCameraRef.current.position, {
			duration: angleDuration,
			z: 6,
			ease: Power1.easeOut,
		}, '<')
		.to(perspectiveCameraRef.current.rotation, {
			duration: angleDuration,
			y: -Math.PI + Math.PI / 4,
		}, '<')
		//endregion
		//region Go back to logo
		scrollTimeline.to(perspectiveCameraRef.current.position, {
			duration: 1,
			x: 0,
			ease: Power1.easeOut,
		})
		.to(perspectiveCameraRef.current.position, {
			duration: 1,
			z: 10,
			ease: Power1.easeIn,
		}, '<')
		.to(perspectiveCameraRef.current.rotation, {
			duration: 1,
			y: 0,
		}, '<')
		.addLabel('lookingAtLogoEnd')
		.call(() => {
			console.log(scrollTimeline.scrollTrigger.direction)
			if (scrollTimeline.scrollTrigger.direction > 0) {
				wrapForward()
			}
		}, null, '>.001')
		.to(perspectiveCameraRef.current.position, {}, '>.5')

		lookingAtLogoPosition = scrollTimeline.scrollTrigger.labelToScroll('lookingAtLogo')
		lookingAtLogoEndPosition = scrollTimeline.scrollTrigger.labelToScroll('lookingAtLogoEnd')

		//endregion

		//region Dispose
		return (() => {
			scrollTimeline.kill()
			scrollTimeline = null
		})
		//endregion
	}, [])
	//endregion

	//region Window width and resize
	const [isMobile, setIsMobile] = useState(false)

	const handleWindowResize = () => {
		setIsMobile(window.innerWidth < 768)
	}

	useEffect(() => {
		handleWindowResize()
		window.addEventListener('resize', handleWindowResize, false)
	}, [])
	//endregion

	//region Camera props
	const [
		{
			enableOrbitControls,
			...perspectiveCameraProps
		}, setPerspectiveCameraProps,
	] = useControls(
		'Main camera',
		() => {
			return {
				enableOrbitControls: false,
				near: 2.4,
				far: 30,
				position: {
					value: [
						0,
						1.2,
						20,
					],
					step: .01,
				},
				rotation: {
					value: [
						0,
						0,
						0,
					],
					step: Math.PI / 32,
				},
				setPosition: button(() => {
					setPerspectiveCameraProps({
						position: [
							perspectiveCameraRef.current.position.x,
							perspectiveCameraRef.current.position.y,
							perspectiveCameraRef.current.position.z,
						],
					})
				}),
			}
		},
		{collapsed: true},
	)

	const [{...currentCameraProps}, setCurrentCameraProps] = useControls('Current camera', () => {
		return {
			position: [0, 0, 0],
			rotation: [0, 0, 0],
		}
	})

	useFrame(() => {
		if (!!perspectiveCameraRef.current) {
			setCurrentCameraProps({
				position: [
					perspectiveCameraRef.current.position.x,
					perspectiveCameraRef.current.position.y,
					perspectiveCameraRef.current.position.z,
				],
				rotation: [
					perspectiveCameraRef.current.rotation.x,
					perspectiveCameraRef.current.rotation.y,
					perspectiveCameraRef.current.rotation.z,
				],
			})
		}
	})
	//endregion

	//region Debug
	const orthographicCameraProps = useControls('Othographic camera', {
		zoom: 70,
	})
	useHelper(window['debugActive'] && perspectiveCameraRef, CameraHelper)
	//endregion

	return (
		<>
			<PerspectiveCamera
				makeDefault={!window['debugActive']}

				fov={isMobile ? 58 : 43}

				ref={perspectiveCameraRef}
				{...perspectiveCameraProps}
			/>

			{
				enableOrbitControls && <OrbitControls/>
			}
			{
				window['debugActive'] &&
				<>
					<Trail
						target={perspectiveCameraRef}

						width={1}
						length={10}
						decay={.0001}
					/>

					<OrthographicCamera
						makeDefault
						{...orthographicCameraProps}
						position-y={35}
						rotation-x={-Math.PI / 2}
					/>
				</>
			}
		</>
	)
}

export default Z_Camera
