import React, { useEffect, useState } from 'react'
import Z_Canvas                       from '../Z_Canvas'

import '../App.sass'
import { Leva }                       from 'leva'

const Z_App = () => {
	//region States
	const [ letsGo, setLetsGo ]                   = useState( false )
	const [ hasScrolled, setHasScrolled ]         = useState( false )
	const [ loadingProgress, setLoadingProgress ] = useState( 0 )
	//endregion
	
	//region Effects
	useEffect( () => {
		document.body.style.overflow = letsGo ? 'auto' : 'hidden'
	}, [ letsGo ] )
	//endregion
	
	//region Handlers
	const handleLetsGo = () => {
		setLetsGo( true )
	}
	//endregion
	
	return (
			<>
				<Leva collapsed={ true }
				      hidden={ process.env.NODE_ENV === 'production' } />
				<div className={ `overlay ${ letsGo ? 'middle-hidden' : 'middle-shown' }` }>
					<div className="top">
						<a className="gax-logo"
						   href="https://gax.design">
							<img src="/logo_gax_studio.svg"
							     alt="Logo Gax Studio" />
						</a>
						
						{ letsGo &&
						  <div className="links">
							  <a href="https://gax.design"
							     className={ 'button' }>gax.design</a>
							  <a href="https://gax.design/contact"
							     className={ 'button' }>Contact us</a>
						  </div>
						}
					</div>
					
					<div className={ `middle` }>
						<div className="content">
							<div className="left">
								<h1>
									Welcome<br />
									To our<br />
									immersive<br />
									portfolio
								</h1>
							</div>
							
							<div className="right">
					<span className="tip">
						to move auround,<br />
						just scroll and click<br />
						on the projects for more details
					</span>
								
								<button type={ 'button' }
								        className={ 'button' }
								        onClick={ handleLetsGo }
								>
									<span className="loader" style={{left: `${loadingProgress*100}%`}}></span>
									LET'S GO
								</button>
							</div>
						</div>
					</div>
					
					<div className={ `bottom ${ !letsGo || hasScrolled ? 'hidden' : '' }` }>
						<div className="text">Scroll</div>
						
						<svg className={ 'arrow' }
						     width="58"
						     height="88"
						     viewBox="0 0 58 88"
						     xmlns="http://www.w3.org/2000/svg">
							<path d="M29 0.25C29.8288 0.25 30.6237 0.57924 31.2097 1.16529C31.7958 1.75134 32.125 2.5462 32.125 3.375V77.0813L51.7875 57.4125C52.3743 56.8257 53.1701 56.4961 54 56.4961C54.8298 56.4961 55.6257 56.8257 56.2125 57.4125C56.7993 57.9993 57.1289 58.7952 57.1289 59.625C57.1289 60.4548 56.7993 61.2507 56.2125 61.8375L31.2125 86.8375C30.9222 87.1285 30.5774 87.3594 30.1977 87.517C29.818 87.6745 29.411 87.7556 29 87.7556C28.589 87.7556 28.1819 87.6745 27.8023 87.517C27.4226 87.3594 27.0778 87.1285 26.7875 86.8375L1.7875 61.8375C1.20071 61.2507 0.871052 60.4548 0.871052 59.625C0.871052 58.7952 1.20071 57.9993 1.7875 57.4125C2.37429 56.8257 3.17015 56.4961 4 56.4961C4.82985 56.4961 5.62571 56.8257 6.2125 57.4125L25.875 77.0813V3.375C25.875 2.5462 26.2042 1.75134 26.7903 1.16529C27.3763 0.57924 28.1712 0.25 29 0.25Z" />
						</svg>
					</div>
				</div>
				
				<Z_Canvas setHasScrolled={ setHasScrolled }
				          setLoadingProgress={ setLoadingProgress } />
			</>
	)
}

export default Z_App
