import React, { Suspense, useEffect, useState } from 'react'
import * as THREE                     from 'three'
import { Text }                       from '@react-three/drei'
import useUpdateEffect                from '../hooks/useUpdateEffect'

export class ZProject {
	id: number
	title: string
	description: string
	imageUrl: string
	position: {
		x: number,
		y: number,
		z: number,
	}
	rotation: number[]
	
	constructor( id: number,
	             title: string,
	             imageUrl: string,
	             description: string,
	             position: { x: number; y: number; z: number },
	             rotation: number[] ) {
		this.id          = id
		this.title       = title
		this.imageUrl    = imageUrl
		this.description = description
		this.position    = position
		this.rotation    = rotation
	}
	
	getPosition() {
		return new THREE.Vector3( this.position.x, this.position.y, this.position.z )
	}
	
	getEulerRotation() {
		return new THREE.Euler( this.rotation[ 0 ], this.rotation[ 1 ], this.rotation[ 2 ] )
	}
}

const Z_Project = ( {
	                    project,
	                    handleImageClick,
	                    big,
                    } ) => {
	
	// States
	const [ geometry, setGeometry ] = useState( null )
	const [ material, setMaterial ] = useState( null )
	const [ hovered, setHovered ]   = useState( false )
	
	//region Load
	useEffect( () => {
		// Texture
		const imgTexture = new THREE.TextureLoader().load(
				project.imageUrl,
				texture => {
					
					// Get image height and width
					const imageWidth  = texture.source.data.naturalWidth
					const imageHeight = texture.source.data.naturalHeight
					
					texture.encoding    = THREE.sRGBEncoding
					texture.needsUpdate = true
					
					texture.center.set( .5, .5 )
					// Change image geometry
					setGeometry( new THREE.PlaneGeometry( imageWidth / imageHeight * 2, 2 ) )
					
					texture.image.crossOrigin = ''
				},
		)
		
		// Material
		const imgMaterial       = new THREE.MeshBasicMaterial( {
			                                                       map:  imgTexture,
			                                                       side: THREE.FrontSide,
		                                                       } )
		imgMaterial.needsUpdate = true
		
		// Insert material into the image
		setMaterial( imgMaterial )
	}, [] )
	//endregion
	
	// Change hovered state
	useUpdateEffect( () => {
		document.body.style.cursor = hovered ? 'pointer' : 'auto'
	}, [ hovered ] )
	
	
	//region Handlers
	const handleClick = ( event: any ) => {
		event.stopPropagation()
		handleImageClick( project )
	}
	//endregion
	
	return (
			<group
					name="project"
					position={ project.getPosition() }
					rotation={ project.getEulerRotation() }
			>
				<group name={ 'project_thumbnail' }
				       scale={ big ? 1.5 : 1 }
						//				       position={ big ? [0,.5,0] : [ 0, 0, 0 ] }
				>
					<mesh
							onClick={ handleClick }
							onPointerEnter={ () => setHovered( true ) }
							onPointerLeave={ () => setHovered( false ) }
							
							geometry={ geometry }
							material={ material }
					>
					</mesh>
				</group>
				
				<group
						position={ big ? [ -.95, -1.625, 0 ] : [ -.6, -1.125, 0 ] }
				>
					<Suspense>
						<Text
								font={ '/fonts/Fontfabric - Mont Regular.otf' }
								fontSize={ .12 }
								
								textAlign={ 'left' }
								anchorX="left"
								anchorY="top"
						>
							{ project.title }
						</Text>
						<Text
								position={ [ 0, -0.175, 0 ] }
								
								font={ '/fonts/Fontfabric - Mont Regular.otf' }
								fontSize={ .075 }
								
								textAlign={ 'left' }
								anchorX="left"
								anchorY="top"
						>
							{ project.description }
						</Text>
					</Suspense>
				</group>
			</group>
	)
}

export default function Z_Projects(
		{
			projects,
			sectionIndex,
			handleImageClick,
		} ) {
	
	return (
			<>
				{
					projects.map( ( project, projectIndex ) => {
						return (
								<Z_Project project={ project }
								           key={ project.title }
								           big={ sectionIndex === 1 && projectIndex === 2 }
								           handleImageClick={ handleImageClick }
								/>
						)
					} )
				}
			</>
	)
}
