import React           from 'react'
import ReactDOM        from 'react-dom/client'
import './index.sass'
import reportWebVitals from './reportWebVitals'
import Z_App           from './Components/Z_App'

const debugActive       = window.location.hash == '#debug'
window[ 'debugActive' ] = debugActive

const root = ReactDOM.createRoot(
		document.getElementById( 'z_canvas_three_js' ) as HTMLElement,
)

root.render(
		<React.StrictMode>
			<Z_App />
		</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
