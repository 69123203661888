import { Canvas }                               from '@react-three/fiber'
import React, { Suspense, useEffect, useState } from 'react'
import Z_Model                                  from './Components/Z_Model'
import Z_Sections                               from './Components/Z_Sections'
import Z_Camera                                 from './Components/Z_Camera'
import { Z_Selected_Project }                   from './Components/Z_Selected_Project'
import { ZProject }                             from './Components/Z_Projects'

import * as THREE           from 'three'
import { Sky, useProgress } from '@react-three/drei'
import { Perf }             from 'r3f-perf'
import { useControls }      from 'leva'

const Z_Canvas = ( {
	                   setHasScrolled,
	                   setLoadingProgress,
                   } ) => {
	//region States
	const [ selectedProject, setSelectedProject ] = useState( null )
	//endregion
	
	const {
		      progress,
		      item,
		      loaded,
		      total,
	      } = useProgress()
	useEffect( () => {
		setLoadingProgress( loaded / 21 )
	}, [ progress ] )
	
	// Update body overflow
	useEffect( () => {
		document.body.style.overflow = selectedProject !== null ? 'hidden' : ''
	}, [ selectedProject ] )
	
	//region Handlers
	const handleImageClick = ( project: ZProject ) => {
		setSelectedProject( project )
	}
	
	const handleCloseProject = () => {
		setSelectedProject( null )
	}
	//endregion
	
	//region Debug
	const { showPerf } = useControls( 'Show performances', {
		showPerf: false,
	} )
	//endregion
	
	return (
			<>
				<Canvas
						gl={ {
							toneMapping:    THREE.LinearToneMapping,
							outputEncoding: THREE.sRGBEncoding,
						} }
						
						tabIndex={ -1 }
						className={ window[ 'debugActive' ] ? 'debugActive' : '' }
						onKeyDown={ event => {
							if ( selectedProject ) {
								if ( event.key === 'Escape' ) {
									handleCloseProject()
								}
							}
						}
						}
				>
					{ showPerf && <Perf position={ 'top-left' } /> }
					{ window[ 'debugActive' ] &&
					  <>
						  <gridHelper args={ [ 30, 30 * 4 ] } />
						  <gridHelper args={ [ 30, 30, 'red', 'black' ] } />
					  </>
					}
					
					<Suspense fallback={ null }>
						<Sky
								sunPosition={ [ 10, 30, 10 ] }
								distance={ 40 }
						/>
						
						<group>
							<Z_Model />
							
							<Z_Sections handleImageClick={ handleImageClick } />
						</group>
					</Suspense>
					
					<Z_Camera setHasScrolled={ setHasScrolled } />
				</Canvas>
				
				{
					<Z_Selected_Project selectedProject={ selectedProject }
					                    closeProject={ handleCloseProject } />
				}
			</>
	)
}

export default Z_Canvas
