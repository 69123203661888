//region Imports
import React, { useEffect, useState } from 'react'
import { useGLTF, useTexture }        from '@react-three/drei'
import { useControls }                from 'leva'
import * as THREE                     from 'three'
//endregion

const Z_Model = () => {
	//region Load resources
	//region GLB
	//@ts-ignore
	const { nodes } = useGLTF( 'ThreeJS_Files/GAX_portfolio.glb' )
	
	// Meshes
	const [ floor_walls_stairs, setFloorWallsStairs ] = useState( null )
	const [ galais, setGalais ]                       = useState( null )
	
	// Load meshes on first load
	useEffect( () => {
		setFloorWallsStairs( nodes.Scene.children.find( child => child.name === 'sol_murs_marche_Baked' ) )
		setGalais( nodes.Scene.children.find( child => child.name === 'galais001_Baked' ) )
	}, [] )
	
	//region Textures
	const floorWallsStairsBakedTexture = useTexture( '/ThreeJS_Files/murs.jpg' )
	const galaisBakedTexture           = useTexture( '/ThreeJS_Files/centre.jpg' )
	
	// Material
	const [ floorWallsStairsBakedMaterial, setFloorWallsStairsBakedMaterial ] = useState( null )
	const [ galaisBakedMaterial, setGalaisBakedMaterial ]                     = useState( null )
	
	//region Update textures
	floorWallsStairsBakedTexture.flipY    = false
	floorWallsStairsBakedTexture.encoding = THREE.sRGBEncoding
	
	galaisBakedTexture.flipY    = false
	galaisBakedTexture.encoding = THREE.sRGBEncoding
	//endregion
	
	useEffect( () => {
		// Set material from texture
		setFloorWallsStairsBakedMaterial( new THREE.MeshBasicMaterial( {
			                                                               map:         floorWallsStairsBakedTexture,
			                                                               transparent: true,
			                                                               alphaTest:   1,
		                                                               } ) )
		setGalaisBakedMaterial( new THREE.MeshBasicMaterial( {
			                                                     map:         galaisBakedTexture,
			                                                     transparent: true,
			                                                     alphaTest:   1,
		                                                     } ) )
	}, [] )
	//endregion
	
	//region Debug props
	const floorWallsStairsDebugProps = useControls( 'Floor Walls Stairs', {
		scale: 1,
	}, { collapsed: true } )
	const galaisDebugProps           = useControls( 'Galais', {
		scale:    1,
		position: [ 0, 0, 0 ],
	}, { collapsed: true } )
	//endregion
	//endregion
	
	//region Debug to remove
	const [ debugPropsToRemove, setDebugPropsToRemove ] = useControls( 'Debug to remove', () => {
		return {
			pointerPosition: {
				x: 0,
				y: 0,
				z: 0,
			},
		}
	}, { collapsed: true } )
	//endregion
	
	return (
			<group name={ 'model' }
			       rotation={ [ 0, Math.PI, 0 ] }
			       position={ [ 0, -1, 0 ] }
			>
				{/*Floor walls stairs*/ }
				<mesh name={ 'floor_walls_stairs' }
				      { ...floorWallsStairsDebugProps }
				      geometry={ floor_walls_stairs?.geometry }
				      material={ floorWallsStairsBakedMaterial }
				
				      onClick={ ( e ) => {
					      setDebugPropsToRemove( { pointerPosition: { ...( e.intersections[ 0 ].point ) } } )
				      } }
				/>
				
				{/*Galais*/ }
				<mesh name={ 'galais' }
				      { ...galaisDebugProps }
				      geometry={ galais?.geometry }
				      material={ galaisBakedMaterial }
						//                      material={ new THREE.MeshBasicMaterial( { color: 'red' } ) }
				/>
			</group>
	)
}

export default Z_Model
