import React, { useState } from 'react'
import axios               from 'axios'
import useUpdateEffect     from '../hooks/useUpdateEffect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner }       from '@fortawesome/free-solid-svg-icons'

export const Z_Selected_Project = ( {
	                                    selectedProject,
	                                    closeProject,
                                    } ) => {
	
	// States
	const [ loading, setLoading ]                      = useState( false )
	const [ content, setContent ]                      = useState( '' )
	const [ backgroundColor, setBackgroundColor ]      = useState( '' )
	const [ textColor, setTextColor ]                  = useState( '' )
	const [ oldSelectedPoject, setOldSelectedProject ] = useState( '' )
	
	useUpdateEffect( () => {
		// If there is a selected project
		if ( selectedProject ) {
			if ( oldSelectedPoject === selectedProject ) {
				setLoading( false )
			} else {
				setOldSelectedProject( selectedProject )
				setLoading( true )
				// Get new content
				const query = `{
							project(id: ${ selectedProject.id }, idType: DATABASE_ID){
								textColor
								backgroundColor
								content
							}
						}`
				
				axios.post( 'https://www.gax-bo.admin-ziben.fr/graphql', {
					     query: query,
				     } )
				     .then( res => {
					     setLoading( false )
					     setContent( res.data.data.project.content )
					     setBackgroundColor( res.data.data.project.backgroundColor )
					     setTextColor( res.data.data.project.textColor )
				     } )
			}
		}
	}, [ selectedProject ] )
	
	//@ts-ignore
	return (
			<>
				{ selectedProject &&
				  <div className="selected-project"
				       onClick={ ( event ) => {
					       // If the element clicked is the .selected-project div and not a child -> close
					       if ( event.currentTarget === event.target ) {
						       setLoading( true )
						       closeProject()
					       }
				       } }
				  >
					  { loading
					    ? <div className="loading">
						    <FontAwesomeIcon icon={ faSpinner } />
					    </div>
					    : <div className="content"
					           style={ {
						           backgroundColor: backgroundColor,
						           color:           textColor,
					           } }
					           dangerouslySetInnerHTML={ { __html: content } }></div>
					  }
					  
					  <svg
							  className={ 'close-icon' }
							  onClick={ () => {
								  closeProject()
							  }
							  }
							  width="71"
							  height="70"
							  viewBox="0 0 71 70"
							  fill="none"
							  xmlns="http://www.w3.org/2000/svg">
						  <line x1="1.35355"
						        y1="0.646447"
						        x2="70.3536"
						        y2="69.6464"
						        stroke="white" />
						  <line x1="0.646447"
						        y1="69.6464"
						        x2="69.6464"
						        y2="0.646449"
						        stroke="white" />
					  </svg>
				  </div>
				}
			</>
	)
}
